/* General sidebar styling */
.navigation-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
}

.sidebar {
    width: 80px;
    /* width: 250px; */
    background-color: #0a1f34;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    transition: width 0.3s;
    z-index: 1000;
}

.sidebar.open {
    width: 250px;
}

.menu {
    margin-top: 2rem;
    overflow: scroll;
}

.link {
    display: flex;
    align-items: center;
    padding: 1rem;
    color: white;
    text-decoration: none;
    transition: background 0.3s;
}

.link:hover,
.active {
    background-color: #bf4377;
}

.icon {
    margin-right: 10px;
    font-size: 1.5rem;
}

.link_text {
    font-size: 1.2rem;
    display: inline-block;
}

.toggle-btn {
    padding: 1rem;
    cursor: pointer;
    font-size: 3rem;
    color: white;
}

.link_logout {
    padding: 1rem;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.icon_logout {
    font-size: 1.5rem;
    margin-right: 10px;
}

.content {
    flex-grow: 1;
    margin-left: 250px;
    transition: margin-left 0.3s;
    /* padding: 2rem; */
}

.content.shifted {
    margin-left: 80px;
}

/* Bottom navigation for smaller screens */
.bottom-nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #0a1f34;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    z-index: 1000;
}

.nav-item {
    color: white;
    font-size: 1.2rem;
    text-align: center;
    flex: 1;
}

.toggle-center {
    font-size: 1.5rem;
    cursor: pointer;
}

/* Menu Overlay for smaller screens */
.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    color: white;
    cursor: pointer;
}

.menu-overlay-content {
    text-align: center;
}

.menu-overlay-link {
    display: block;
    color: white;
    font-size: 1.5rem;
    margin: 1rem 0;
    text-decoration: none;
    cursor: pointer;
}

.logout_link{
    color: red;

}

.menu-overlay-link:hover {
    color: #bf4377;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .sidebar {
        display: none;
    }

    .bottom-nav {
        display: flex;
    }

    .content {
        margin-left: 0;
    }
}
