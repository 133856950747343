.admin-footer {
    background-color: #0a1f34;
    color: white;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #4A6FB1;
}

.footer-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 1rem;
}

.footer-section {
    flex: 1;
    margin: 1rem;
}

.powered-by p {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    color: #bf4377;
}

.footer-links {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.footer-links li {
    margin: 0;
}

.footer-links li a {
    color: #a9b3bc;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
}

.footer-links li a:hover {
    color: #bf4377;
}

.footer-bottom {
    text-align: center;
    width: 100%;
    padding-top: 1rem;
    border-top: 1px solid #4A6FB1;
}

.footer-bottom p {
    margin: 0;
    font-size: 1rem;
    color: #a9b3bc;
}

/* Responsive */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        text-align: center;
        margin: 1rem 0;
    }

    .footer-links {
        flex-direction: column;
    }
}
